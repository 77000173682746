import mock from './mock'
import './tables/datatables'
import './autoComplete/autoComplete'
import './navbar/navbarSearch'
import './apps/calendar'
import './pages/blog-data'
import './cards/card-analytics'
import './cards/card-statistics'

mock.onAny().passThrough()
